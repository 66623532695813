
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_USER } from "~/store/auth/getters"

export default Vue.extend({
  name: "NavLoginButton",
  props: {
    services: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    },
    redirectUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showDropdown: false
    }
  },
  computed: {
    ...mapGetters("auth", {
      user: GET_USER
    }),
    username(): string {
      if (!this.user) {
        return ""
      }
      return this.user?.username || ""
    }
  },
  methods: {
    showLogin() {
      this.$emit("show-login-form")
    },
    toggleDropdown(show: boolean) {
      if (this.username) {
        this.showDropdown = show
      } else {
        this.showLogin()
      }
    }
  }
})
