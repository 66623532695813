
import { Component, Prop, mixins, Getter, Action, Watch } from "nuxt-property-decorator"
import { ValidationObserver } from "vee-validate"
import axios from "axios"
import Blocks from "~/mixins/blocks"
import { GET_LISTING, GET_ITEMS_COUNT } from "~/store/listing/getters"
import {
  ListingService,
  getCategoryById,
  getV4Url,
  getV4UrlByCategoryId,
  getV4ListingPayload,
  addPreListingIdToSessionStorage,
  isValidLocation,
  isValidPropertyType
} from "~/services/public/Listing"
import { AVAILABILITY_REMARK, CALL_US, RETRIEVE_QUOTE, VALIDATION } from "~/constants/blocks"
import { redirectToV2 } from "~/utils/v4-redirect"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"
import eventBus from "~/scripts/bus"
import { SET_DELIVERY_PROPERTY_TYPE, SET_PICKUP_PROPERTY_TYPE } from "~/store/listing/actions"
import { SET_INVENTORY, SET_WINDOW_WIDTH } from "~/store/pages/actions"
import { extractHref, getInnerText } from "~/scripts/useful-functions"
import { getFurnitureDefaultInventory } from "~/services/public/ItemService"
import {
  GET_INVENTORY_DESKTOP,
  GET_IS_SMALL_OR_MEDIUM,
  GET_WINDOW_WIDTH
} from "~/store/pages/getters"

@Component({
  methods: { extractHref, getInnerText },
  components: {
    ValidationObserver
  }
})
export default class LocationForm extends mixins(Blocks) {
  @Prop({ type: Number, default: 0 }) categoryV4Id: number | undefined
  @Prop({ type: Boolean, default: false }) isRemovalsForm: boolean
  @Prop({ type: Boolean, default: false }) isCtaForm: boolean
  @Prop({ type: Boolean, default: false }) hasExtension: boolean
  @Prop({ required: true, type: Object }) block: Record<string, any>
  @Getter(GET_WINDOW_WIDTH, { namespace: "pages" }) getWindowWidth: number
  @Getter(GET_IS_SMALL_OR_MEDIUM, { namespace: "pages" }) isSmallOrMedium: boolean
  @Getter(GET_INVENTORY_DESKTOP, { namespace: "pages" }) getInventoryDesktop: boolean
  @Getter(GET_LISTING, { namespace: "listing" }) getListing: Listing | null
  @Getter(GET_ITEMS_COUNT, { namespace: "listing" }) getItemsCount: number
  @Getter(GET_PHONE_NUMBER_VALUE, { namespace: "phone-number" })
  getPhoneNumberValue: PhoneNumberPair

  @Action(SET_PICKUP_PROPERTY_TYPE, { namespace: "listing" }) setPickupPropertyType: Function
  @Action(SET_DELIVERY_PROPERTY_TYPE, { namespace: "listing" }) setDeliveryPropertyType: Function

  @Watch("submittingForm") onSubmittingFormChanges(val, oldVal) {
    if (val) {
      eventBus.$emit("submitting-location-form")
    } else {
      eventBus.$emit("submitted-location-form")
    }
  }

  get isLargeOrAbove() {
    return !this.isSmallOrMedium
  }

  get locationBlock() {
    return this.categoryV4Id ? this.block.innerBlocks[0] : this.block.innerBlocks[1]
  }

  get propertyBlock() {
    if (!this.isRemovalsForm) {
      return null
    }

    return this.block.innerBlocks[this.block.innerBlocks.length - 2]
  }

  get ctaBlock() {
    return this.block.innerBlocks[this.block.innerBlocks.length - 1]
  }

  get ctaFormButtons() {
    if (this.isCtaForm) {
      return this.block.innerBlocks[0].innerBlocks[0].innerBlocks[0].innerBlocks
    }
  }

  get ctaFormHasMultipleButtons() {
    if (this.isCtaForm) {
      return this.ctaFormButtons.length > 1
    }
  }

  submittedForm: boolean = false
  submittingForm: boolean = false
  emailFormShow: boolean = false
  trustpilotCustomers = "0"
  showFurnitureItems: boolean | null = null
  initInventory: boolean = false
  itemCategories: Record<string, any> | null = null

  $refs!: {
    [x: string]: any
    observer: InstanceType<typeof ValidationObserver>
  }

  async created() {
    this.$store.dispatch("pages/" + SET_INVENTORY, null)
    const trustPilotConfig = this.$config.trustPilot
    const trustpilotURL = `https://api.trustpilot.com/v1/business-units/${trustPilotConfig.id}`

    try {
      const axiosInstance = axios.create() as any
      const response = await axiosInstance.get(trustpilotURL, {
        params: {
          apikey: trustPilotConfig.apiKey
        }
      })
      if (response.status === 200 && response.data) {
        this.trustpilotCustomers = Number(response.data.numberOfReviews.total).toLocaleString()
      }
    } catch (err) {
      console.log("Trustpilot error", err)
    }
  }

  setMobileOrDesktop() {
    this.$store.dispatch("pages/" + SET_WINDOW_WIDTH, window.innerWidth)
  }

  beforeMount() {
    eventBus.$on("submit-location-form", this.submitForm)
    this.setMobileOrDesktop()
    if (!this.initInventory) {
      window.addEventListener("resize", () => {
        this.setMobileOrDesktop()
      })
    }
  }

  async mounted() {
    if (!this.initInventory) {
      this.initInventory = true
      // Safety mechanism to disable IoLP Desktop when LocationFormExtension is missing
      if (this.hasExtension && !this.getInventoryDesktop) {
        this.hasExtension = false
      }
      if (this.getInventoryComputed) {
        this.itemCategories = (await getFurnitureDefaultInventory()).items
      } else {
        // @ts-ignore-next-line
        window.dataLayer?.push({ event: "show_responseiq" })
      }

      const inventoryMobile = this.getInventoryComputed && this.isSmallOrMedium
      await this.$store.dispatch("pages/" + SET_INVENTORY, inventoryMobile)

      // const inventoryDesktop = this.getInventoryComputed && !this.getIsSmallOrMedium
      // if (inventoryDesktop) {
      //   // Enable Sentry Replay tagged with Snowplow Domain UserID
      //   const { getReplay, setTag } = await import("@sentry/vue")
      //   getReplay()?.start()
      //   setTag("sp.domain_userid", this.$snowplow.getSnowplowDomainIds().domain_userid)
      // }

      const ua = navigator.userAgent.toLowerCase()
      // The implemented workaround only works on Safari & Chrome with iOS 16 & iOS 17
      if (
        ua.includes("safari") &&
        navigator.maxTouchPoints > 2 &&
        (ua.includes("version/16") ||
          ua.includes("version/17") ||
          ua.includes("iphone os 16_") ||
          ua.includes("iphone os 17_"))
      ) {
        if (ua.includes("chrome")) {
          document.body.classList.add("chrome")
        } else {
          document.body.classList.add("safari")
        }
      }
    }
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.setMobileOrDesktop)
    eventBus.$off("submit-location-form")
  }

  get getInventoryComputed() {
    return !this.isRemovalsForm && (this.isSmallOrMedium || this.hasExtension)
  }

  get hasAvailabilityRemark() {
    return this.Blocks_patternCheck(this.block.attributes, AVAILABILITY_REMARK)
  }

  get hasCallUs() {
    return this.Blocks_patternCheck(this.block.attributes, CALL_US)
  }

  get hasRetrieveQuote() {
    return this.Blocks_patternCheck(this.block.attributes, RETRIEVE_QUOTE)
  }

  get isValidForm() {
    const listing = this.getListing
    if (this.hasValidation(this.locationBlock)) {
      if (!isValidLocation(listing?.pickup) || !isValidLocation(listing?.delivery)) {
        return false
      }
    }
    if (this.isRemovalsForm && this.hasValidation(this.propertyBlock)) {
      if (
        !isValidPropertyType(listing?.pickup.propertyType) ||
        !isValidPropertyType(listing?.delivery.propertyType)
      ) {
        return false
      }
    }

    return true
  }

  get hasItemsDesktop() {
    return this.isLargeOrAbove && this.hasExtension && this.getItemsCount
  }

  async tryToOpenFurnitureItemsModal() {
    if (!this.itemCategories) {
      this.itemCategories = (await getFurnitureDefaultInventory()).items
    }
    if (this.isValidForm) {
      this.showFurnitureItems = true
    } else {
      this.$snowplow.trackIOLPButtonDisabled(this.getItemsCount, "Form")
      await this.$refs.observer.validate()
    }
  }

  scrollInputToTop(el) {
    if (this.getWindowWidth && this.getWindowWidth > 768) return
    // @ts-ignore
    let element
    switch (el) {
      case "pickup":
        element = this.$refs.pickupInput
        break
      case "delivery":
        element = this.$refs.deliveryInput
        break
      default:
        return
    }
    if (element && element.$el.offsetTop) {
      window.scrollTo({
        top: element.$el.offsetTop - 25,
        behavior: "smooth"
      })
    }
  }

  async submitForm(isCalledFromPage = true) {
    if (this.submittingForm) return
    this.submittedForm = true
    this.submittingForm = true
    try {
      const listing = this.getListing
      if (!isValidPropertyType(listing?.pickup.propertyType)) {
        await this.setPickupPropertyType(null)
      }
      if (!isValidPropertyType(listing?.delivery.propertyType)) {
        await this.setDeliveryPropertyType(null)
      }

      const isFirstStepValid = await this.$refs.observer.validate()
      const isSecondStepValid = !this.getInventoryComputed || !!this.getItemsCount
      let isValid = isFirstStepValid
      if (isValid) {
        isValid = isSecondStepValid
      }
      if (!isValid) {
        if (this.getInventoryComputed && isCalledFromPage) {
          const errors = (isFirstStepValid ? "" : "Form") + (isSecondStepValid ? "" : "Items")
          this.$snowplow.trackCTAButtonDisabled("page", this.getItemsCount, errors)
        }
        this.submittingForm = false
        eventBus.$emit("close-dropdown")
        this.hideItems()
        return
      }

      if (this.getInventoryComputed && isCalledFromPage) {
        this.$snowplow.trackCTAButton("page", this.getItemsCount)
      }

      let categoryV4Id = this.categoryV4Id
      if (!this.categoryV4Id && listing?.category_id && getCategoryById(listing?.category_id)) {
        categoryV4Id = getCategoryById(listing?.category_id)?.v4Id
      }

      this.$snowplow.trackButtonClick(
        getInnerText(this.ctaBlock.innerBlocks[1].innerBlocks[0].innerBlocks[0].innerHtml),
        this.$config.v4Host + "/" + getV4UrlByCategoryId(categoryV4Id)
      )

      if (!categoryV4Id) {
        window.location.href = this.$config.v4Host + "/" + getV4UrlByCategoryId(categoryV4Id)
        this.submittingForm = false
        return
      }

      const payload = getV4ListingPayload(listing, categoryV4Id)
      if (payload.v === 2) {
        redirectToV2(payload, this.$config.v4Host)
        this.submittingForm = false
        return
      }

      const listingService = new ListingService(this.$axios, this.$config)
      const response: ListingResponse = await listingService.postPreListing(payload)

      if (response?.data && response.data?.pre_listing_id && response.data?.category_id) {
        if (response.is_international) {
          redirectToV2(payload, this.$config.v4Host)
          this.submittingForm = false
          return
        }

        addPreListingIdToSessionStorage(response.data)
        document.addEventListener("visibilitychange", () => {
          this.submittingForm = false
          this.submittedForm = false
          this.showFurnitureItems = false
        })
        window.location.href = getV4Url(this.$config.v4Host, response.data, payload)
        return
      } else {
        this.submittingForm = false
        if (this.getInventoryComputed) {
          this.$snowplow.trackCTAButtonFailed(
            "page",
            this.getItemsCount,
            "PreListing creation failed"
          )
        }
      }
    } catch (err) {
      this.submittingForm = false
      if (this.getInventoryComputed) {
        this.$snowplow.trackCTAButtonFailed(
          "page",
          this.getItemsCount,
          "PreListing creation error" + err
        )
      }
      console.log("PreListing creation error", err)
    }
  }

  hasValidation(block) {
    return this.Blocks_patternCheck(block.attributes, VALIDATION)
  }

  onEmailFormOpen() {
    this.emailFormShow = true
  }

  onEmailFormClose() {
    this.emailFormShow = false
  }

  handleCallUsClick(label = "location_form_button") {
    this.$snowplow.trackCallUs({
      phoneNumber: this.getPhoneNumberValue.baseRaw,
      label,
      action: "click",
      extension: this.getPhoneNumberValue.extension
    })
  }

  hideItemsOnClickOutside(event) {
    // If the user clicked at the Summary or Create/Edit Item dialog then we should not close the dropdown.
    if (event.target.closest(".extension-block") || event.target.closest("#modalContent")) return
    eventBus.$emit("close-dropdown")
    this.hideItems()
  }

  hideItems() {
    setTimeout(() => {
      this.showFurnitureItems = false
    }, 500)
  }
}
