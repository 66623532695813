
import Vue from "vue"

export default Vue.extend({
  name: "DIYBreadCrumb",
  props: {
    block: {
      required: true,
      type: Object as () => Block
    }
  },
  methods: {
    isBreadcrumb(key: number) {
      return key === 0 || key % 2 === 0
    }
  }
})
