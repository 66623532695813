/**
 * Style guide:
 * https://move.anyvan.com/index.html
 */

const plugin = require("tailwindcss/plugin")

module.exports = {
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true
  },
  theme: {
    screens: {
      xs: "480px",
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
      xxl: "1800px",
      rebrandLg: "1512px",
      rebrandNav: "1320px"
    },
    container: {
      center: true,
      padding: "1rem"
    },
    fontFamily: {
      lato: ["Lato", "sans-serif"],
      latoLight: ["LatoLatinWebLight"],
      latoMedium: ["LatoLatinWebMedium"],
      latoSemibold: ["LatoLatinWebSemibold"],
      latoBold: ["LatoLatinWebBold"],
      latoBlack: ["LatoLatinWebBlack"]
    },
    // based on 16px
    fontSize: {
      rebrandXs: "0.875rem", // 14px
      base: "1rem", // 16px
      rebrandSm: "1.25rem", // 20px
      rebrandMd: "1.5rem", // 24px
      rebrandXm: "1.75rem", // 28px
      rebrandLg: "2.125rem", // 34px
      rebrand2xm: "2.5rem", // 40px
      rebrandXl: "2.75rem", // 44px
      rebrand2xl: "3.125rem", // 50px
      rebrand3xl: "3.625rem", // 58px
      "3xs": "0.6875rem", // 11px
      "2xs": "0.75rem", // 12px
      xs: "0.813rem", // 13px
      sm: "0.875rem", // 14px
      sm2: "0.938rem", // 15px
      base2: "1.0625rem", // 17px
      lg: "1.125rem", // 18px
      xl: "1.25rem", // 20px
      xl2: "1.375rem", // 22px
      "2xl": "1.5rem", // 24px
      "2xl2": "1.625rem", // 26px
      "2xl3": "1.75rem", // 28px
      "3xl": "1.875rem", // 30px
      "3xl2": "2rem", // 32px
      "3xl3": "2.25rem", // 36px
      "4xl": "2.5rem", // 40px
      "5xl": "2.75rem", // 44px
      "5xl2": "2.875rem", // 46px
      "6xl": "3rem", // 48px
      "7xl": "3.25rem", // 52px
      "8xl": "3.625rem" // 58px
    },
    // based on 16px
    lineHeight: {
      none: 1,
      normal: 1.2,
      "2xs": "0.75rem", // 12px
      xs: "0.6875rem", // 13px
      sm: "0.875rem", // 14px
      base: "1rem", // 16px
      lg: "1.125rem", // 18px
      xlb: "1.20rem", // 19.2px (looks like Lato-Bold 20px)
      xl: "1.25rem", // 20px
      xl2: "1.375rem", // 22px
      "2xl": "1.5rem", // 24px
      "2xl2": "1.625rem", // 26px
      "2xl3": "1.6875rem", // 27px
      "3xl": "1.75rem", // 28px
      "4xl": "1.875rem", // 30px
      "5xl": "2rem", // 32px
      "5xl2": "2.125rem", // 34px
      "6xl": "2.5rem", // 40px
      "7xl": "3rem", // 48px
      "7xl2": "3.125rem", // 50px
      "8xl": "3.5rem", // 56px
      "9xl": "3.875rem" // 62px
    },
    letterSpacing: {
      normal: "0",
      wide: "0.3px",
      wider: "0.6px"
    },
    borderRadius: {
      none: "0",
      DEFAULT: "5px",
      3: "3px",
      6: "6px",
      8: "8px",
      9: "9px",
      12: "12px",
      20: "20px",
      32: "32px",
      full: "50%"
    },
    colors: {
      // blunt instrument that creates needless tailwind classes, read: excess CSS. Split into textColor, borderColor etc.
      inherit: "inherit",
      transparent: "transparent",
      white: "#fff",
      black: "#000",
      black5: "rgba(0, 0, 0, 0.05)",
      black20: "rgba(0, 0, 0, 0.2)",
      black10: "rgba(0, 0, 0, 0.1)",
      black50: "rgba(0, 0, 0, 0.5)",
      black60: "rgba(0, 0, 0, 0.6)",
      black70: "rgba(0, 0, 0, 0.7)",
      anyvan: {
        "cta-primary": "#FF465C",
        "cta-secondary": "#0F2240",
        "semantic-negative": "#FFBD6F",
        "semantic-positive": "#C9ECBE",
        "semantic-information": "#CEF8FA",
        "semantic-neutral": "#F3F3F3",
        "grey-light": "#E4E4E4",
        "grey-medium": "#C5C5C5",
        "grey-dark": "#939393",
        "grey-darkest": "#636363",
        green: "#5BD09F",
        pink: "#F36096",
        "pink-soft": "#FFC1D8",
        yellow: "#FFC907",
        "yellow-soft": "#FFECA8",
        "yellow-dark": "#DFA205",
        blue: {
          dark: "#266386",
          pale: "#E2F5FF"
        }
      },
      border: {
        inputs: "rgba(0, 0, 0, 0.5)"
      },
      blue: {
        primary: "#41a5dd",
        dark: "#294169",
        soft: "#B1E3FF"
      },
      red: {
        primary: "#f74f64"
      },
      green: {
        primary: "#70c216",
        darker: "#01b975"
      },
      yellow: {
        lighter: "#fefce9"
      },
      gray: {
        100: "#f7f7f7",
        110: "#f7e9eb",
        130: "#f5f5f5",
        140: "#f6f6f6",
        200: "#f0f0f0",
        220: "#eaeaea",
        230: "#efefef",
        250: "#e3e3e3",
        300: "#d8d8d8",
        310: "#d4d4d4",
        320: "#dddddd",
        380: "#c7c7c7",
        400: "#bcbcbc",
        500: "#adadad",
        550: "#a4a4a4",
        570: "#9b9b9b",
        580: "#979797",
        600: "#909090",
        650: "#6e6e6e",
        700: "#4d4d4d",
        710: "#444444",
        800: "#292929"
      },
      background: "#f7f7f7",
      contrast: {
        lightest: "#d8d8d8",
        lighter: "#979797",
        medium: "#6e6e6e",
        high: "#4d4d4d",
        higher: "#292929"
      },
      primary: "#41a5dd",
      secondary: "#2788be",
      "secondary-new": "#b1e3ff",
      tetriary: "#1e4c71",
      positive: "#70c216",
      caution: "#ffa233",
      critical: "#f74f64",
      active: "#dc493a",
      avb: "#216477",
      "avb-darker": "#284E5B",
      "avb-lighter": "#57BEC6",
      "avb-purple": "#390040",
      "avb-persimmon": "#FC511F",
      "avb-slate": "#8F9EA2",
      "avb-caribbean": "#11F0B8",
      "avb-lemon": "#F0FF3D",
      "avb-malachite": "#1BFE48",
      "rating-yellow": "#FFCC33",
      "removals-section-bg": "#EEF2F5",
      flamingo: "#EE6352",
      "deep-sea": "#0C7C59",
      "viola-black": "#56445D",
      "dark-blue": "#304D6D",
      "medium-purple": "#7B61FF",
      "summerday-blue": "#376B94",
      gumbo: "#769EA6",
      "salt-box": "#6A5F6F",
      lochmara: "#0079CB",
      "eastern-blue": "#2580B1",
      "light-blue": "#E6F1F9",
      "petrol-blue": "#0F2240"
    },
    textColor: {
      // colours are repeated here as a temporary measure.
      base: {
        dark: "rgba(0,0,0,0.7)"
      },
      inherit: "inherit",
      transparent: "transparent",
      white: "#fff",
      black: "#000",
      black5: "rgba(0, 0, 0, 0.05)",
      black20: "rgba(0, 0, 0, 0.2)",
      black10: "rgba(0, 0, 0, 0.1)",
      black50: "rgba(0, 0, 0, 0.5)",
      black60: "rgba(0, 0, 0, 0.6)",
      black70: "rgba(0, 0, 0, 0.7)",
      anyvan: {
        "cta-primary": "#FF465C",
        "cta-secondary": "#0F2240",
        "semantic-negative": "#FFBD6F",
        "semantic-positive": "#C9ECBE",
        "semantic-information": "#CEF8FA",
        "semantic-neutral": "#F3F3F3",
        "grey-light": "#E4E4E4",
        "grey-medium": "#C5C5C5",
        "grey-dark": "#939393",
        "grey-darkest": "#636363",
        green: "#5BD09F",
        "green-soft": "#BBECD8",
        pink: "#F36096",
        "pink-soft": "#FFC1D8",
        yellow: "#FFC907",
        "yellow-soft": "#FFECA8",
        "yellow-dark": "#DFA205",
        blue: {
          dark: "#266386",
          pale: "#E2F5FF"
        },
        "red-alert": "#D92239"
      },
      border: {
        inputs: "rgba(0, 0, 0, 0.5)"
      },
      blue: {
        primary: "#41a5dd",
        dark: "#294169"
      },
      red: {
        primary: "#f74f64"
      },
      green: {
        primary: "#70c216",
        darker: "#01b975"
      },
      yellow: {
        lighter: "#fefce9"
      },
      gray: {
        100: "#f7f7f7",
        110: "#f7e9eb",
        130: "#f5f5f5",
        200: "#f0f0f0",
        220: "#eaeaea",
        230: "#efefef",
        300: "#d8d8d8",
        310: "#d4d4d4",
        320: "#dddddd",
        380: "#c7c7c7",
        400: "#bcbcbc",
        500: "#adadad",
        550: "#a4a4a4",
        570: "#9b9b9b",
        580: "#979797",
        600: "#909090",
        650: "#6e6e6e",
        700: "#4d4d4d",
        710: "#444444",
        800: "#292929"
      },
      background: "#f7f7f7",
      contrast: {
        lightest: "#d8d8d8",
        lighter: "#979797",
        medium: "#6e6e6e",
        high: "#4d4d4d",
        higher: "#292929"
      },
      primary: "#41a5dd",
      secondary: "#2788be",
      "secondary-new": "#b1e3ff",
      tetriary: "#1e4c71",
      positive: "#70c216",
      caution: "#ffa233",
      critical: "#f74f64",
      active: "#dc493a",
      avb: "#216477",
      "avb-darker": "#284E5B",
      "avb-lighter": "#57BEC6",
      "avb-purple": "#390040",
      "avb-persimmon": "#FC511F",
      "avb-slate": "#8F9EA2",
      "avb-caribbean": "#11F0B8",
      "avb-lemon": "#F0FF3D",
      "avb-malachite": "#1BFE48",
      "rating-yellow": "#FFCC33",
      "removals-section-bg": "#EEF2F5",
      flamingo: "#EE6352",
      "deep-sea": "#0C7C59",
      "viola-black": "#56445D",
      "dark-blue": "#304D6D",
      "medium-purple": "#7B61FF",
      "summerday-blue": "#376B94",
      gumbo: "#769EA6",
      "salt-box": "#6A5F6F",
      lochmara: "#0079CB",
      "eastern-blue": "#2580B1",
      "light-blue": "#E6F1F9",
      "petrol-blue": "#0F2240"
    },
    spacing: {
      rebrandXs: "24px",
      rebrandSm: "32px",
      rebrandMd: "48px",
      rebrandLg: "64px",
      px: "1px",
      0: "0",
      2: "2px",
      3: "3px",
      4: "4px",
      5: "5px",
      6: "6px",
      8: "8px",
      9: "9px",
      10: "10px",
      11: "11px",
      12: "12px",
      14: "14px",
      15: "15px",
      16: "16px",
      18: "18px",
      20: "20px",
      21: "21px",
      22: "22px",
      23: "23px",
      24: "24px",
      25: "25px",
      26: "26px",
      28: "27px",
      30: "30px",
      32: "32px",
      33: "33px",
      34: "34px",
      35: "35px",
      36: "36px",
      38: "38px",
      40: "40px",
      42: "42px",
      44: "44px",
      45: "45px",
      46: "46px",
      48: "48px",
      50: "50px",
      51: "51px",
      52: "52px",
      54: "54px",
      56: "56px",
      58: "58px",
      60: "60px",
      62: "62px",
      64: "64px",
      66: "66px",
      68: "68px",
      70: "70px",
      72: "72px",
      74: "74px",
      90: "90px",
      100: "100px",
      120: "120px",
      155: "155px",
      250: "250px"
    },
    boxShadow: {
      b: "0 0 10px 0 rgba(0, 0, 0, 0.11)",
      rebrand: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
      inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
      fbox: "0 4px 6px 0 rgba(0, 0, 0, 0.08)",
      none: "none"
    }
  },
  variants: {},
  plugins: [
    plugin(function ({ addUtilities, variants }) {
      // these font sizes works with 'ms-' prefix
      const sizes = [
        { size: "3xs", value: -4 }, // _0.624em / _9.36px | _9.99px | 10.61px | 11.24px
        { size: "2xs", value: -3 }, // _0.702em / 10.53px | 11.24px | 11.94px | 12.64px
        { size: "xs", value: -2 }, // __0.79em_ / 11.85px | 12.64px | 13.43px | 14.22px
        { size: "sm", value: -1 }, // __0.889em / 13.33px | 14.22px | 15.11px | 16.00px
        { size: "base", value: 0 }, // _1em____ / 15.00px | 16.00px | 17.00px | 18.00px <==== BASE
        { size: "lg", value: 1 }, // ___1.125em / 16.88px | 18.00px | 19.13px | 20.25px
        { size: "xl", value: 2 }, // ___1.266em / 18.98px | 20.25px | 21.52px | 22.78px
        { size: "2xl", value: 3 }, // __1.424em / 21.36px | 22.78px | 24.21px | 25.63px
        { size: "3xl", value: 4 }, // __1.602em / 24.03px | 25.63px | 27.23px | 28.83px
        { size: "4xl", value: 5 }, // __1.802em / 27.03px | 28.83px | 30.63px | 32.44px
        { size: "5xl", value: 6 }, // __2.027em / 30.41px | 32.44px | 34.46px | 36.49px
        { size: "6xl", value: 7 }, // __2.281em / 34.21px | 36.49px | 38.77px | 41.05px
        { size: "7xl", value: 8 }, // __2.566em / 38.49px | 41.05px | 43.62px | 46.18px
        { size: "8xl", value: 9 }, // __2.887em / 43.30px | 46.18px | 49.07px | 51.96px
        { size: "9xl", value: 10 }, // _3.247em / 48.71px | 51.96px | 55.20px | 58.45px
        { size: "10xl", value: 11 } // 3.653em / 54.84px | 58.50px | 62.15px | 65.80px
      ]
      const base = 1
      const ratio = 1.125
      const unit = "em"

      const ms = function (size) {
        const value = Math.pow(ratio, size) * base

        if (unit === "px") {
          return Math.round(value) + unit
        } else {
          return value.toFixed(3) + unit
        }
      }

      const utilities = sizes.map(({ size, value }) => ({
        [`.ms-${size}`]: {
          fontSize: ms(value)
        }
      }))

      addUtilities(utilities, variants)
    })
  ],
  content: [
    "components/**/*.vue",
    "layouts/**/*.vue",
    "pages/**/*.vue",
    "plugins/**/*.js",
    "nuxt.config.js",
    // TypeScript
    "plugins/**/*.ts",
    "nuxt.config.ts",
    "scripts/css-helper.ts"
  ],
  safelist: [
    "md:flex",
    "lg:flex",
    "lg:hidden",
    "md:hidden",
    "lg:text-center",
    {
      pattern:
        /^(bg-|text-|border-|float-|mr-|ml-|mt-|mb-|mx-|my-|m-|ms-|opacity-|h-|w-|size-|svg|fa|stroke|fill)/
    }
  ]
}
