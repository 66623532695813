
import { Component, Prop, mixins } from "nuxt-property-decorator"
import {
  CATEGORY_LOCATION_FORM,
  CHECK_LIST,
  COMPARISON_TABLE_ITEM,
  COMPARISON_TABLE_SECTION,
  CORE_BUTTON,
  CORE_COVER,
  CORE_IMAGE,
  CORE_PARAGRAPH,
  FAQ_SECTION,
  HEADER,
  HOME_PAGE_HEADER,
  ICON_TABLE,
  IMAGE_DIALOG,
  IMAGE_OVERLAY,
  IMAGE_TEXT_SECTION,
  INTERNAL_REVIEWS,
  INTERNAL_REVIEW_SECTION,
  LINK_PHONE,
  LINK_PHONE_LINK_ONLY,
  LINK_TABLE,
  REMOVALS_FORM,
  CTA_FORM,
  LOCATION_FORM,
  LOCATION_FORM_EXTENSION,
  OTHER_SERVICES_SECTION,
  PRICE_BANNER,
  RECENT_MOVES_SECTION,
  RESPONSIVE_IMAGE_SET,
  TRUSTPILOT_CAROUSEL,
  CAROUSEL_SWIPER,
  VALUE_ADD_SECTION,
  CATEGORY_CARD,
  TRUSTPILOT_WIDGET,
  HEADER_CARDS,
  MEDIA_TEXT_RESPONSIVE,
  EXPERIMENT,
  MAPBOX_LOCAL_MOVES_MAP,
  HEADER_NARROW_LHS,
  MAP_PANEL_CTAS,
  AV_SAVINGS,
  AV_LOCAL_SUMMARY,
  SERVICE_HIGHLIGHT,
  BREADCRUMB
} from "~/constants/blocks"
import { CATEGORY_V4_ID_FURNITURE, CATEGORY_V4_ID_HOUSE_MOVE } from "~/constants/category"
import Blocks from "~/mixins/blocks"

@Component
export default class BlockComponent extends mixins(Blocks) {
  @Prop({ required: true, type: Object as () => Block }) block: Block
  @Prop({ default: "", type: String }) blockId: string
  @Prop({ default: false, type: Boolean }) virgin: boolean
  @Prop({ default: false, type: Boolean }) lazy: boolean
  @Prop({ default: false, type: Boolean }) isRebrand: boolean
  @Prop({ default: false, type: Boolean }) isPPC: boolean

  CATEGORY_LOCATION_FORM: string = CATEGORY_LOCATION_FORM
  CATEGORY_V4_ID_FURNITURE: number = CATEGORY_V4_ID_FURNITURE
  CATEGORY_V4_ID_HOUSE_MOVE: number = CATEGORY_V4_ID_HOUSE_MOVE
  CHECK_LIST: string = CHECK_LIST
  COMPARISON_TABLE_ITEM: string = COMPARISON_TABLE_ITEM
  COMPARISON_TABLE_SECTION: string = COMPARISON_TABLE_SECTION
  CORE_BUTTON: string = CORE_BUTTON
  CORE_COVER: string = CORE_COVER
  CORE_IMAGE: string = CORE_IMAGE
  CORE_PARAGRAPH: string = CORE_PARAGRAPH
  FAQ_SECTION: string = FAQ_SECTION
  HEADER: string = HEADER
  HEADER_NARROW_LHS: string = HEADER_NARROW_LHS
  ICON_TABLE: string = ICON_TABLE
  IMAGE_DIALOG: string = IMAGE_DIALOG
  IMAGE_OVERLAY: string = IMAGE_OVERLAY
  IMAGE_TEXT_SECTION: string = IMAGE_TEXT_SECTION
  INTERNAL_REVIEWS: string = INTERNAL_REVIEWS
  INTERNAL_REVIEW_SECTION: string = INTERNAL_REVIEW_SECTION
  LINK_TABLE: string = LINK_TABLE
  REMOVALS_FORM: string = REMOVALS_FORM
  CTA_FORM: string = CTA_FORM
  LOCATION_FORM: string = LOCATION_FORM
  LOCATION_FORM_EXTENSION: string = LOCATION_FORM_EXTENSION
  OTHER_SERVICES_SECTION: string = OTHER_SERVICES_SECTION
  PRICE_BANNER: string = PRICE_BANNER
  RECENT_MOVES_SECTION: string = RECENT_MOVES_SECTION
  RESPONSIVE_IMAGE_SET: string = RESPONSIVE_IMAGE_SET
  TRUSTPILOT_CAROUSEL: string = TRUSTPILOT_CAROUSEL
  CAROUSEL_SWIPER: string = CAROUSEL_SWIPER
  VALUE_ADD_SECTION: string = VALUE_ADD_SECTION
  LINK_PHONE: string = LINK_PHONE
  LINK_PHONE_LINK_ONLY: string = LINK_PHONE_LINK_ONLY
  HOME_PAGE_HEADER: string = HOME_PAGE_HEADER
  CATEGORY_CARD: string = CATEGORY_CARD
  HEADER_CARDS: string = HEADER_CARDS
  EXPERIMENT: string = EXPERIMENT

  TRUSTPILOT_WIDGET: string = TRUSTPILOT_WIDGET
  MAPBOX_LOCAL_MOVES_MAP: string = MAPBOX_LOCAL_MOVES_MAP
  MAP_PANEL_CTAS: string = MAP_PANEL_CTAS
  MEDIA_TEXT_RESPONSIVE: string = MEDIA_TEXT_RESPONSIVE
  AV_SAVINGS: string = AV_SAVINGS
  AV_LOCAL_SUMMARY: string = AV_LOCAL_SUMMARY
  SERVICE_HIGHLIGHT: string = SERVICE_HIGHLIGHT
  BREADCRUMB: string = BREADCRUMB

  get phoneNumber() {
    const phoneNumberMatch = this.block.innerHtml.match(/<a[^>]*>+(.*)<\/a>/)

    return phoneNumberMatch ? phoneNumberMatch[1] : null
  }

  get imageId() {
    const imageId = this.block.attributes.find(attribute => attribute.name === "id")

    return imageId ? imageId.value : null
  }

  get imageUrl() {
    if (!this.block.innerHtml) {
      return null
    }

    const regex = /src="([^"]+)"/
    // @ts-ignore -- Object is possibly 'null'.
    const src = regex.exec(this.block.innerHtml)

    if (src) {
      return src[1]
    }

    console.warn("The page includes image without source!")
    return null
  }

  get attributeClasses() {
    return this.Blocks_getAttributes(this.block).class
  }

  get getImageData() {
    const imageData = {
      id: this.imageId,
      url: this.imageUrl
    }

    const width = this.Blocks_getAttribute(this.block.attributes, "width")
    const height = this.Blocks_getAttribute(this.block.attributes, "height")

    if (width && height) {
      Object.assign(imageData, { width, height })
    }

    return imageData
  }

  get imageCaption() {
    const regex = /<figcaption>([^"]+)<\/figcaption>/
    // @ts-ignore -- Object is possibly 'null'.
    const caption = regex.exec(this.block.innerHtml)

    if (caption) {
      return caption[1]
    }

    return null
  }

  isLinkOnly(innerHtml) {
    const pHtml = innerHtml.replace(/<a[^>]*>(.+?)<\/a>/, "")
    const pText = pHtml.replace(/<p[^>]*>(.+?)<\/p>/, "$1")

    return !pText
  }

  getHref(innerHtml) {
    const hrefArray = /href="([^'"]+)/g.exec(innerHtml)
    if (hrefArray) {
      return hrefArray[1]
    }

    return ""
  }

  getLink(innerHtml) {
    return innerHtml.replace(/(<([^>]+)>)/gi, "")
  }

  getLinkClass(block) {
    const attributes = this.Blocks_getAttributes(block)
    return attributes.class?.replace("av-paragraph ", "")
  }
}
