
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"
import TrustPilotWidget from "~/components/generic/TrustPilotWidget.vue"

export default Vue.extend({
  name: "PanelCTAs",
  components: { TrustPilotWidget },
  data() {
    return {
      formSubmitted: false
    }
  },
  computed: {
    ...mapGetters("phone-number", {
      getPhoneNumberValue: GET_PHONE_NUMBER_VALUE
    })
  },
  methods: {
    submitForm() {
      this.$emit("submit-form")
    },
    handleCallUsClick() {
      this.$snowplow.trackCallUs({
        phoneNumber: this.getPhoneNumberValue.baseRaw,
        label: "panel_cta_button",
        action: "click",
        extension: this.getPhoneNumberValue.extension
      })
      window.location.href = `tel:${this.getPhoneNumberValue.base}`
    }
  }
})
