import { defineComponent } from "vue"

interface Data {
  showMenu: boolean
}

export default defineComponent({
  data(): Data {
    return {
      showMenu: false
    }
  },

  methods: {
    toggleMenu(override: boolean | null = null): void {
      if (override !== null) {
        this.showMenu = override
      } else {
        this.showMenu = !this.showMenu
      }

      if (this.showMenu) {
        this.$emit("hide-other-menus")
      }
    },

    closeAllMenus(): void {
      this.toggleMenu(false)
      this.$emit("hide", false)
    }
  }
})
