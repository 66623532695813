
import Vue from "vue"

export default Vue.extend({
  props: {
    block: { required: true, type: Object as () => Block },
    blockId: { default: "", type: String },
    narrowLhs: { default: false, type: Boolean },
    isPPC: { default: false, type: Boolean }
  }
})
