
import { Vue } from "nuxt-property-decorator"

export default Vue.extend({
  name: "HeaderCards",
  methods: {
    trackCardClick(title: string) {
      this.$snowplow.trackCategoryCardClick(title)
    }
  }
})
