
import Vue from "vue"

import { getScreenWidth } from "~/scripts/useful-functions"
import NavOurServicesMixin from "~/mixins/navOurServices"
import NavHelpCentreMixin from "~/mixins/navHelpCentre"

interface ComponentRefs {
  ourServicesMenu: InstanceType<typeof NavOurServicesMixin> & Vue
  helpCentreMenu: InstanceType<typeof NavHelpCentreMixin> & Vue
}

export default Vue.extend({
  name: "TheRebrandHeader",
  data() {
    return {
      showLoginForm: false
    }
  },
  methods: {
    toggleOurServices(override: null | boolean = null) {
      const ourServicesMenu = this.$refs
        .ourServicesMenu as unknown as ComponentRefs["ourServicesMenu"]
      if (ourServicesMenu) {
        ourServicesMenu.toggleMenu(override)
      }
    },
    toggleHelpCentre(override: null | boolean = null) {
      const helpCentreMenu = this.$refs.helpCentreMenu as unknown as ComponentRefs["helpCentreMenu"]
      if (helpCentreMenu) {
        helpCentreMenu.toggleMenu(override)
      }
    },
    hideOurServicesDesktop() {
      const screenWidth = getScreenWidth()
      if (screenWidth && screenWidth >= 768) {
        this.toggleOurServices(false)
      }
    },
    hideHelpCentreDesktop() {
      const screenWidth = getScreenWidth()
      if (screenWidth && screenWidth >= 768) {
        this.toggleHelpCentre(false)
      }
    },
    track(property: string, label: string) {
      this.$snowplow.trackNavMenuClickEvent(property, label)
    }
  }
})
