import { defineComponent } from "vue"

interface Data {
  showMenu: boolean
  showAllServices: boolean
}

export default defineComponent({
  data(): Data {
    return {
      showMenu: false,
      showAllServices: false
    }
  },

  methods: {
    toggleMenu(override: boolean | null = null): void {
      if (override !== null) {
        this.showMenu = override
      } else {
        this.showMenu = !this.showMenu
      }

      if (this.showMenu) {
        this.$emit("hide-other-menus")
      }

      if (!this.showMenu) {
        setTimeout(() => {
          this.toggleAllServices(false)
        }, 350)
      }
    },

    toggleAllServices(override: boolean | null = null): void {
      if (override !== null) {
        this.showAllServices = override
      } else {
        this.showAllServices = !this.showAllServices
      }
    },

    toggleHelpCentre(override: boolean | null = null): void {
      this.$emit("show-help-centre", override)
    },

    handleLoginbutton(): void {
      this.$emit("show-login-form")
      this.toggleMenu(false)
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
  }
})
